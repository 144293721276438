import { defineStore } from 'pinia'
import { logoutFn } from '../api/auth/oauth'
import { socketDisconnectFn } from '@/composables/socketEvents'

export const useOauthInfo = defineStore('oauthInfo', {
  state: () => ({
    verifyMail: '' // 驗證信箱
  }),
  getters: {
  },
  actions: {
    async logout () { // 登出
      const res = await logoutFn()
      const data = res.data.value
      if (data.code === 200) {
        this.logoutProcess()
      }
    },
    logoutProcess () { // 登出處理
      // 斷開socket連線
      socketDisconnectFn()
      this.removeLocalStorage()
      navigateTo('/login')
    },
    removeLocalStorage () {
      localStorage.removeItem('token')
      localStorage.removeItem('tenantId')
      localStorage.removeItem('convListConfig')
      localStorage.removeItem('currentPage')

      localStorage.removeItem('verifyId') // TODO 確認是否有需要存
      localStorage.removeItem('currentChannel') // TODO 確認是否有需要存
      localStorage.removeItem('sucRating') // TODO 確認是否有需要存
    },
    resetStore () {
      const rolesInfo = useRolesInfo()
      const userInfo = useUserInfo()
      const convInfo = useConvInfo()
      const channelInfo = useChannelInfo()
      const teamInfo = useTeamInfo()

      userInfo.$reset()
      convInfo.$reset()
      channelInfo.$reset()
      teamInfo.$reset()
      rolesInfo.$reset()
    }
  }
})
